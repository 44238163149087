body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-text-color {
  color: #ffc107;
}

.gray-text-color {
  color: gray;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#upload-new-passenger-photos-row,
.steps-journey-creation-row {
  display: flex;
  flex-direction: column;
  min-height: 60vh;
}

#body-container {
  flex: 1;
  min-height: 75vh;
}

.add-static-btn {
  position: fixed;
  bottom: 3rem;
  right: 2rem;
  z-index: 2;
}

.full-image {
  width: 98.9vw;
  object-fit: cover;
}

.full-wrapper-image {
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .add-static-btn {
    bottom: 1.5rem;
    right: 1rem;
  }
}

@media screen and (max-width: 1440px) {
  .full-image {
    width: 100vw;
  }
}

/* Footer */

.footer-link {
  text-decoration: none;
}

.footer-link:hover {
  color: #ffc107 !important;
}
